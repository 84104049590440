import * as React from 'react';
import clsx from 'clsx';
import Dialog, { DialogHeader } from 'components/Dialog';
import { debounce } from 'lodash';
import { useGlobalSearch } from 'hooks/useGlobalSearch';
import { Search, X } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import Button from 'components/Button';
import Heading from 'components/text/Heading';
import Link from 'components/Link';
import { getItemLink } from 'utils/featuredLIst';
import Paragraph from 'components/text/Paragraph';
import { useRouter } from 'next/router';
import { routes } from 'utils/routes';
import analytics, { ANALYTIC_EVENTS } from 'utils/firebase/analytics';

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const GlobalSearchMobileDialog: React.FC<Props> = ({ open, onOpenChange }) => {
  const [value, setValue] = React.useState('');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { data, getData } = useGlobalSearch();
  const { t } = useTranslation();
  const router = useRouter();

  React.useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    }
  }, [open]);

  const getDataDebounced = debounce(async val => {
    await getData(val);
    analytics.logEvent(ANALYTIC_EVENTS.GLOBAL_SEARCH, { search: val });
  }, 300);

  const onChange = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setValue(val);
      getDataDebounced(val);
    },
    [getDataDebounced]
  );

  const handleClearSearch = React.useCallback(() => {
    setValue('');
    getDataDebounced('');
  }, [getDataDebounced]);

  const handleKeyPress = React.useCallback(
    e => {
      // on Enter go to shop page
      if (e.keyCode === 13 && !!value) {
        onOpenChange(false);
        setValue('');
        getDataDebounced('');
        router.push({ pathname: routes.shop.index(), query: { query: value } });
      }
    },
    [router, value, getDataDebounced, onOpenChange]
  );

  return (
    <Dialog
      onOpenChange={onOpenChange}
      open={open}
      showClose={false}
      className="GlobalSearchMobileDialog lg:max-w-lg bg-white !gap-0"
      containerClassName="!mt-0 border-t-0 border-b-[1.5px] border-b-neutral-900 !p-0 !gap-0"
    >
      <DialogHeader
        className="self-start !text-left w-full !mt-0 flex !flex-row gap-4 border-b-[1.5px] border-b-neutral-900 !px-6 !py-4"
        fullScreen={false}
      >
        <div className="relative w-full">
          <input
            ref={inputRef}
            value={value}
            onChange={onChange}
            onKeyDown={handleKeyPress}
            aria-label="shop search"
            className={clsx(
              'h-11 w-full py-3 px-10 rounded-[100px] outline-none border-[1.5px] border-neutral-900 placeholder:text-neutral-500 text-neutral-900'
            )}
            placeholder={t('globalSearch.searchPlaceholder') as string}
          />
          <Search
            className={clsx('absolute text-neutral-900 top-[13px] left-4')}
            strokeWidth={2}
            size={18}
          />
          {value && (
            <X
              className={clsx('absolute text-neutral-900 top-[13px] right-4 ')}
              strokeWidth={2}
              size={18}
              onMouseDown={handleClearSearch}
            />
          )}
        </div>
        <Button variant="link" onClick={() => onOpenChange(false)}>
          {t('globalSearch.cancel')}
        </Button>
      </DialogHeader>
      <div className="flex flex-col p-6 gap-6">
        {!value && (
          <Heading variant="h4" className="!text-subtitle">
            {t('globalSearch.title')}
          </Heading>
        )}
        {data.length > 0 ? (
          data.map((item, index) => (
            <Link
              key={`search-suggestion-${item.slug}-${index}`}
              href={getItemLink(item?.entityType, item?.slug) || ''}
              className="flex gap-3 items-center"
              onClick={() => onOpenChange(false)}
            >
              <Search strokeWidth={1.5} size={16} className="min-w-4" />
              <Paragraph
                className="text-ellipsis overflow-hidden whitespace-nowrap text-sm"
                variant="body1"
              >
                <span className="font-semibold">{item.businessName}</span> {item.label}
              </Paragraph>
            </Link>
          ))
        ) : (
          <Paragraph variant="body1"></Paragraph>
        )}
      </div>
    </Dialog>
  );
};

export default GlobalSearchMobileDialog;
