import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { X } from 'lucide-react';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import { inter } from 'pages/_app';
const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = ({ children, ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal {...props}>
    <div className={clsx('fixed inset-0 z-50 flex items-center justify-center', inter.className)}>
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
    className?: string;
    onOpenChange?: (open: boolean) => void;
  }
>(({ className, children, onOpenChange, ...props }, ref) => {
  return (
    <div
      className={cx(
        'fixed inset-0 z-50 bg-black/20 dark:bg-white/40 transition-all duration-100 data-[state=closed]:animate-out data-[state=open]:fade-in data-[state=closed]:fade-out',
        className
      )}
      {...props}
      ref={ref}
      {...(onOpenChange && { onClick: () => onOpenChange(false) })}
    />
  );
});
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    fullScreen?: boolean;
    onOpenChange?: (open: boolean) => void;
    containerClassName?: string;
    showClose?: boolean;
  }
>(
  (
    {
      fullScreen,
      className,
      children,
      containerClassName,
      onOpenChange,
      showClose = true,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <DialogPortal>
        <DialogOverlay onOpenChange={onOpenChange} />
        <div
          ref={ref}
          className={cx(
            'fixed z-50 w-full h-full max-h-full sm:max-h-[95%] overflow-auto flex flex-col sm:h-auto gap-4  animate-in data-[state=open]:fade-in-90 data-[state=open]:slide-in-from-bottom-10 sm:max-w-lg sm:rounded-2xl sm:zoom-in-90 data-[state=open]:sm:slide-in-from-bottom-0',
            'bg-white dark:bg-neutral-900 sm:border sm:border-neutral-900',
            className,
            !fullScreen &&
              'px-8 py-9 !h-auto w-auto max-w-[95%] max-h-[95%] border border-neutral-900 rounded-2xl'
          )}
          {...props}
        >
          {!fullScreen ? (
            children
          ) : (
            <div
              className={clsx(
                'flex flex-col gap-4 px-8 pb-9 sm:py-9 border-t-[1.5px] border-t-neutral-900 sm:!border-0 mt-16 sm:mt-0',
                containerClassName
              )}
            >
              {children}
            </div>
          )}
          {showClose && (
            <DialogPrimitive.Close className="absolute flex items-center justify-center bg-neutral-100 hover:bg-neutral-50 w-8 h-8 top-4 right-4 rounded-full transition-opacity hover:opacity-100   focus:outline-none focus:ring-0 focus:ring-neutral-200 focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-neutral-100 dark:focus:ring-neutral-200 dark:focus:ring-offset-neutral-900 dark:data-[state=open]:bg-neutral-800">
              <X strokeWidth={1.5} size={20} />
              <span className="sr-only">{t('general.close')}</span>
            </DialogPrimitive.Close>
          )}
        </div>
      </DialogPortal>
    );
  }
);
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  fullScreen,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { className?: string; fullScreen?: boolean }) => (
  <div
    className={cx(
      'flex flex-col gap-2 text-center items-start mt-9 sm:mt-0 pt-4 sm:pt-0 ',
      className,
      !fullScreen && '!mt-0'
    )}
    {...props}
  />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { className?: string }) => (
  <div
    className={clsx('flex flex-col-reverse sm:flex-row gap-2 justify-center', className)}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & { className?: string }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title ref={ref} className={cx(className)} {...props} />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description> & { className?: string }
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description ref={ref} className={cx(className)} {...props} />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription
};
